import React from "react";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import Members from "./Members/Members";
// import { Members2024}  from "./Members/Members2024.jsx";
export default function TeamPage() {
  return (
    <>
      <ExamplesNavbar />
      <div
        className="wrapper"
        style={{ padding: "80px 0px", background: "#141212" }}
      >
        <div className="content">
          <Members />
          {/* <Members2024/> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
