import React from "react";
import { Container, Col, Row, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
// core components

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

import "./RegisterPage";

import aanchalPic from "../../Pictures/speakers/aanchal.jpg";
import kanthiPic from "../../Pictures/speakers/kanthi.jpeg";
import kendraPic from "../../Pictures/speakers/kendra.jpeg";
import PratikPic from "../../Pictures/speakers/Pratik.jpeg";
import tapanPic from "../../Pictures/speakers/Tapan.png";
import Ashutosh2 from '../../Pictures/speakers/Ashutosh sir 2.jpg'
import Yogendar from '../../Pictures/speakers/Yogendra2.JPEG'
import Sinjini from '../../Pictures/speakers/Sinjini Sengupta .png'
import Prachi from '../../Pictures/speakers/Prachi.JPG'
import "./SpeakerPage.css"

export const members = [
  {
    name: "Aanchal Thakur",
    post: "Alpine Skier",
    image: aanchalPic,
    insta: "https://www.instagram.com/alle.aanchal/",
  },
  {
    name: "Tapan Misra",
    post: "Indian Scientist",
    image: tapanPic,
    insta: "https://www.instagram.com/iamtapanmisra/",
  },
  {
    name: "Kanthi Dutt",
    post: "Entrepreneur",
    image: kanthiPic,
    insta: "https://www.instagram.com/kanthi_dutt/",
  },
  {
    name: "Pratik Gauri",
    post: "Social Entrepreneur",
    image: PratikPic,
    insta: "https://www.instagram.com/pratikgauri/",
  },
  {
    name: "Kenidra Woods",
    post: "Mental Health Advocate/Social Justice Activist",
    image: kendraPic,
    insta: "https://www.instagram.com/kenidra4humanity/",
  },
];
// export const members2 = [
//   {
//     name: "Yogendra Singh Rathore",
//     post: "NLP Coach|Mental Health Coach",
//     image: Yogendar,
//     insta: "https://www.instagram.com/ysr_official/",
//   },
//   {
//     name: "Ashutosh Pratihast",
//     post: "Indian Scientist",
//     image: Ashutosh2,
//     insta: "https://www.instagram.com/ashutoshpratihast/",
//   },
//   {
//     name: "Sinjini Sengupta",
//     post: "Entrepreneur",
//     image: Sinjini,
//     insta: "https://www.instagram.com/sinjinisengupta_official/",
//   },
//   {
//     name: "Prachi Tehlan",
//     post: "Social Entrepreneur",
//     image: Prachic,
//     insta: "https://www.instagram.com/prachitehlan/",
//   },
  
// ];

export default function SpeakerPage(props) {
  React.useEffect(() => {
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("profile-page");
    };
  }, []);

  const renderTeam = () => {
    return members.map((member) => {
      return (
        // <Col
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //   }}
        // >
        //   <div
        //     class="c-container card-content"
        //     style={{
        //       background: `url(${member.image})`,
        //       display: "flex",
        //       backgroundSize: "cover",
        //       backgroundPosition: "center",
        //     }}
        //   >
        //     <div class="inner">
        //       <div class="text-wrapper">
        //         <h1>{member.name}</h1>
        //         <span>{member.post}</span>
        //         <a
        //           data-placement="bottom"
        //           href={`${member.insta}`}
        //           rel="noopener noreferrer"
        //           target="_blank"
        //           style={{
        //             color: "white",
        //             fontSize: "1.3rem",
        //             marginTop: "5px",
        //           }}
        //           className="speaker-card"
        //         >
        //           <i className="fab fa-instagram" />
        //         </a>
        //       </div>
        //     </div>
        //   </div>
        // </Col>
      //   <section class="wrapper-full tab-container effectTab-header" id="style_3">
      //   <div class="wrapper-full tab-item">
      //     <div class="custom-row">
            
      //       <div class="column-12 tab column-xs-12">
      //         <div class="custom-row">
            
              
      //           <div class="column-4 hide-xs hide-sm hide-md box-tab">
      //             <div class="effect effect-three col3-block-height">
      //               <img src="https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80" class="img-fluid"/>
      //               <div class="tab-text">
      //                 <h2>{member.name}</h2>
      //                 <p> <a href="#"><i class="fa fa-facebook-official"></i></a> <a href="#"><i class="fa fa-twitter-square"></i></a> <a href="#"><i class="fa fa-youtube-square"></i></a></p>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      //  </section> 
      <div id="speaker-body">
      <div class="s_card">
      <img src={member.image} class="s_card-img" alt=""/>
      <div class="s_card-body">
           <h1 class="s_card-title">{member.name}</h1>
           <p class="s_card-info">{member.post}</p>
      </div>
     </div>
     </div>
      );
    });
  };


  return (
    <>
      <ExamplesNavbar />

      <div className="wrapper" style={{ padding: "80px 0px" }}>
        <Container>
          <div className="members-unique">
            <div className="Members-container">
              <div>
                <h1 className="Members-heading">Speakers</h1>
                <div id="speaker-body2">
                <div class="s_card">
                  <img src={Ashutosh2} class="s_card-img" alt=""/>
                  <div class="s_card-body">
                  <h1 class="s_card-title">Ashutosh Pratihast</h1>
                  <p class="s_card-info">Entrepreneur, CEO and YouTuber</p>
               </div>
               </div>
                <div class="s_card">
                  <img src={Yogendar} class="s_card-img" alt=""/>
                  <div class="s_card-body">
                  <h1 class="s_card-title">Yogendra Singh Rathore</h1>
                  <p class="s_card-info">NLP Coach</p>
               </div>
               </div>
                <div class="s_card">
                  <img src={Sinjini} class="s_card-img" alt=""/>
                  <div class="s_card-body">
                  <h1 class="s_card-title">Sinjini Sengupta</h1>
                  <p class="s_card-info">Founder of LightHouse| Karmaveer Chakra Award Winner</p>
               </div>
               </div>
                <div class="s_card">
                  <img src={Prachi} class="s_card-img" alt=""/>
                  <div class="s_card-body">
                  <h1 class="s_card-title">Prachi Tehlan</h1>
                  <p class="s_card-info">Former Netball and Basketball Player and Actress</p>
               </div>
               </div>
               </div>
                <h1 className="Members-heading">Past Speakers</h1>
                <Container>
                  <Row>{renderTeam()}</Row>
                </Container>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
}
