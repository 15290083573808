import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./Membersnew.css";
import { TeamMembers as members } from "./TeamMembers";

function Members() {
  const handleClick = () => {
    document.querySelector(".hero, .content").classList.add("scrolled");
  };

  const renderTeam = () => {
    return members.map((member) => {
      return (
        // <Col
        //   style={{
        //     display: "flex",
        //     justifyContent: "center",
        //   }}
        // >
        //   <div
        //     class="c-container card-content"
        //     style={{
        //       background: `url(${member.image})`,
        //       display: "flex",
        //       backgroundSize: "cover",
        //       backgroundPosition: "center",
        //     }}
        //   >
        //     <div class="inner">
        //       <div class="text-wrapper">
        //         <h1>{member.name}</h1>
        //         <span>{member.post}</span>
        //       </div>
        //     </div>
        //   </div>
        // </Col>
      <section id="speaker-body">
      <div class="m_card">
      <img src={member.image} class="m_card-img" alt=""/>
      <div class="m_card-body">
           <h1 class="m_card-title">{member.name}</h1>
           <p class="m_card-info">{member.post}</p>
      </div>
     </div>
     </section>
      );
    });
  };

  return (
    <div className="members-unique">
      <div className="Members-container">
        <div>
          <h1 className="Members-heading">Organising Team</h1>
          <Container>
            <Row>{renderTeam()}</Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Members;

// <div class="hero">
// 	<div class="hero-inner">
// 			<div class="hero-title">
// 				<h1 class="text-light title font-2">time machine</h1>
// 				<p class="text-capitalize text-light">here starts awesomeness</p>
// 			</div>
// 			<a href="#" class="sd">Scroll Down</a>
// 	</div>
// </div>
// <div class="content clearfix">
// 	<div class="container" style="padding-top: 60px">
// 		<div class="row">
// 			<div class="col-md-5">
// 				<h2 class="text-uppercase">rame ipsum</h2>
// 				<p class="font-alt">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui voluptatibus molestia!</p>
// 			</div>
// 			<div class="col-md-7">
// 				<p style="padding-top: 47px">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam earum eos corporis totam vel, eaque sapiente officiis sint culpa inventore blanditiis hic cupiditate illo nam assumenda reprehenderit suscipit dolorum.</p>
// 			</div>
// 		</div>
// 	</div>
// 	<div class="container" style="height: 100vh"></div>
// </div>
