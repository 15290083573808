import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <style>
        {`
          .footer {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .footer-nav {
            display: flex;
            flex-direction: column;
          }
          @media (min-width: 768px) {
            .footer-nav {
              flex-direction: row;
            }
          }
        `}
      </style>
      <Container>
        <Row className="justify-content-center">
          <Col md="3">
            <Nav className="footer-nav">
              <NavItem>
                <NavLink to="/" tag={Link}>
                  Home
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/about" tag={Link}>
                  About Us
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/register" tag={Link}>
                  Register
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/speakers" tag={Link}>
                  Speakers
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/team" tag={Link}>
                  Team
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}