import image1 from "../../Pictures/pic2.jpg";
import image2 from "../../Pictures/tshirt-back.jpg";
import image3 from '../../Pictures/Merchandiseback.jpg';
import image4 from '../../Pictures/Merchandisefront.jpg'

export const contents = [
  {
    id: 1,
    name: "Official Merchandise",
    image: image4,
  },
  {
    id: 2,
    name: "Official Merchandise",
    image: image3,
  },
];
