/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import PriceTable from "./components/PriceTable";
import { Products } from "./components/product";
import SizeChart from "./components/SizeChart";
import { contents } from "./contents";
import "./RegisterPage.css";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar";
import Footer from "../../components/Footer/Footer";

export default function RegisterPage(props) {
  React.useEffect(() => {
    document.body.classList.toggle("profile-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("profile-page");
    };
  }, []);

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <>
          <div className="Items">
            <div class="text-container">
              <svg viewBox="0 0 960 300">
                <symbol id="s-text">
                  <text text-anchor="middle" x="50%" y="80%">
                    Merchandise
                  </text>
                </symbol>

                <g class="g-ants">
                  <use xlinkHref="#s-text" className="text-copy"></use>
                  <use xlinkHref="#s-text" className="text-copy"></use>
                  <use xlinkHref="#s-text" className="text-copy"></use>
                  <use xlinkHref="#s-text" className="text-copy"></use>
                  <use xlinkHref="#s-text" className="text-copy"></use>
                </g>
              </svg>
            </div>
          </div>

          <div className="page-contain">
            <div className="inner-text">
              <p>
                An exclusive range of TEDxNITDurgapur high-quality merchandise
                to inspire and remind you of the event.
              </p>
              <p>
                Available to order online for delivery directly to you. Items
                are in limited stock and are available subject to the
                availability
              </p>
            </div>
            <hr className="line" />

            <div className="Card">
              {contents.map((contents) => (
                <Products key={contents.id} image={contents.image} />
              ))}
            </div>
            <SizeChart />
            <PriceTable />

            <div className="wrap" style={{ marginTop: "30px" }}>
              <button
                class="button register-button"
                onClick={(e) =>
                  (window.location.href = "https://forms.gle/gj8udAcA3pKsiRWj9")
                }
              >
                Register!
              </button>
            </div>
          </div>
        </>
        <Footer />
      </div>
    </>
  );
}
