import React from "react";
import "../RegisterPage";

const SizeChart = () => {
  return (
    <div>
      <h4 style={{ color: "white" }}>Size Chart</h4>

      <table class="t-container">
        <thead>
          <tr>
            <th scope="col">
              <h1>Size</h1>
            </th>
            <th scope="col">
              <h1>Chest Width</h1>
            </th>
            <th scope="col">
              <h1>Total Height</h1>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>XS</td>
            <td>34''</td>
            <td>25.5''</td>
          </tr>

          <tr>
            <td>S</td>
            <td>36''</td>
            <td>26.5''</td>
          </tr>

          <tr>
            <td>M</td>
            <td>38''</td>
            <td>27.5''</td>
          </tr>

          <tr>
            <td>L</td>
            <td>40''</td>
            <td>28.5''</td>
          </tr>

          <tr>
            <td>XL</td>
            <td>42''</td>
            <td>29.5''</td>
          </tr>

          <tr>
            <td>XXL</td>
            <td>44''</td>
            <td>30.5''</td>
          </tr>
        </tbody>
      </table>
      <h2 className="me-text" style={{ marginBottom: "0px" }}>
        Note: All measurements are in inches(1 inch = 2.54cm).
      </h2>
      <h2 className="me-text">Size deviation +/0.5 inches applicable</h2>
    </div>
  );
};

export default SizeChart;
