import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss";
import "./assets/demo/demo.css";

import Index from "./views/Index";
import LandingPage from "./views/examples/LandingPage";
import TeamPage from "./views/examples/TeamPage";
import RegisterPage from "./views/examples/RegisterPage";
import SpeakerPage from "./views/examples/SpeakerPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={(props) => <Index {...props} />} />
      <Route path="/about" render={(props) => <LandingPage {...props} />} />
      <Route path="/team" render={(props) => <TeamPage {...props} />} />
      <Route path="/register" render={(props) => <RegisterPage {...props} />} />
      <Route path="/speakers" render={(props) => <SpeakerPage {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);
