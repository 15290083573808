/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { Apps } from "../../views/timer";
//import { Apps } from "views/timer";
//import { Timer } from "views/timer";
//import { App } from "views/timer";
import "./PageHeader.css";

import logo from "../../Pictures/logo_black_bg.png";

export default function PageHeader() {
  return (
    <div className="page-header header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container>
        <div className="content-center brand">
          <img
            src={logo}
            style={{ marginBottom: "40px", width: "80%", height: "auto" }}
            alt="This is the TEDxNITDurgapur logo"
          />
          <h3
            className="d-sm-block tedx-main-title"
            style={{ fontWeight: "bolder" }}
          >
            A Dreamer's{" "}
            <span style={{ color: "red", fontWeight: "bolder" }}>
              Guide
            </span>{" "}
            to Reality
          </h3>
          <Apps />

          <button
            class="button register-button"
            style={{ marginTop: "50px", width: "100%" }}
            onClick={(e) =>
              (window.location.href = "https://forms.gle/gj8udAcA3pKsiRWj9")
            }
          >
            Register!
          </button>
        </div>
      </Container>
    </div>
  );
}
