import navinPic from "../../../Pictures/posts/navin.png";
import fariaPic from "../../../Pictures/posts/faria.jpg";
import ayushiPic from "../../../Pictures/posts/ayushi.jpg";
import epshitaPic from "../../../Pictures/posts/epshita.jpg";
import ashutoshPic from "../../../Pictures/posts/Ashutosh.jpg";
import yugPic from "../../../Pictures/posts/yug.jpg";
import sagnikPic from "../../../Pictures/posts/sagnik.jpg";
import neelKamalPic from "../../../Pictures/posts/neelkamal.jpg";
import ishanPic from "../../../Pictures/posts/Ishan.jpg";
import pushpalPic from "../../../Pictures/posts/pushpal.jpg";
import shivanshuPic from "../../../Pictures/posts/Shivanshu.jpg";
import shubhamPic from "../../../Pictures/posts/Shubham.jpg";
import tusharPic from "../../../Pictures/posts/Tushar.JPG";
import architPic from "../../../Pictures/posts/archit.jpeg";
import asifPic from "../../../Pictures/posts/asif.jpeg";
import sakshiPic from "../../../Pictures/posts/sakshi.jpg";



export const TeamMembers = [
  {
    name: "Navin Pathak",
    post: "Organizer",
    image: navinPic,
  },
  {
    name: "Faria Tariq",
    post: "Co-Organizer",
    image: fariaPic,
  },
  {
    name: "Ayushi Gupta",
    post: "Chief Curator",
    image: ayushiPic,
  },
  {
    name: "Epshita Chakravarty",
    post: "Chief Curator",
    image: epshitaPic,
  },
  {
    name: "Ashutosh Kumar Singh Rathore",
    post: "Director Of Marketing & Communications",
    image: ashutoshPic,
  },
  {
    name: "Yug Soni",
    post: "Director Of Marketing & Communications",
    image: yugPic,
  },
  {
    name: "Sagnik Khan",
    post: "Director Of Operations",
    image: sagnikPic,
  },

  {
    name: "Neel Kamal",
    post: "Director Of Operations",
    image: neelKamalPic,
  },

  {
    name: "Sakshi",
    post: "Director Of Design & Video Production",
    image: sakshiPic,
  },

  {
    name: "Shubham Paul",
    post: "Director Of Design & Video Production",
    image: shubhamPic,
  },

  {
    name: "Ishan Gupta",
    post: "Director of Technical Operations",
    image: ishanPic,
  },

  {
    name: "Archit Lall",
    post: "Director of Technical Operations",
    image: architPic,
  },


  {
    name: "Asif Khan",
    post: "Director Of Finance",
    image: asifPic,
  },
  {
    name: "Tushar Yadav",
    post: "Director Of Finance",
    image: tusharPic,
  },
  {
    name: "Pushpal Ghodaskar",
    post: "Executive Producer",
    image: pushpalPic,
  },

  {
    name: "Shivanshu Basu Mallick",
    post: "Executive Producer",
    image: shivanshuPic,
  },
];
