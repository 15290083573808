/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import nit_durgapur from "../../Pictures/nit_durgapur.jpg"

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar";
import Footer from "../../components/Footer/Footer";
import "./LandingPage.css";

export default function LandingPage() {
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);


  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper" style={{ paddingTop: "100px" }}>
        <Container>
          <Row className="row-grid justify-content-between align-items-center text-left">
            <Col xs={12} md={6} lg={6}>
              <h1 className="text-white">
                <span>What is TED?</span>
              </h1>
              <p className="text-white mb-3">
                Founded in 1984, TED is a nonprofit devoted to spreading ideas,
                usually in the form of short powerful talks. A global community,
                wherein people regardless of their discipline or culture can
                seek a deeper understanding of the world, TED believes
                vehemently in the power of ideas to alter attitudes, lives and
                ultimately the world, for the better. TED Conferences are
                dedicated to the organization’s global mission of researching
                and sharing meaningful new ideas, in order to assist in
                amplifying ideas and innovations that matter. TED’s initiatives
                are a winning formula of brilliant, curious minds and
                groundbreaking content in an immersive and educational
                environment.
              </p>
              <div className="btn-wrapper mb-3">
                <Button
                  className="btn-link"
                  color="success"
                  href="https://www.ted.com/"
                  size="sm"
                >
                  <p className="category text-success d-inline">Know More</p>
                  <i className="tim-icons icon-minimal-right" />
                </Button>
              </div>
              <div className="btn-wrapper">
                <div className="button-container">
                  <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="https://twitter.com/TEDTalks"
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="https://www.instagram.com/ted/"
                  >
                    <i className="fab fa-instagram" />
                  </Button>
                  {/* <Button
                    className="btn-icon btn-simple btn-round btn-neutral"
                    color="default"
                    href="https://www.facebook.com/TED"
                  >
                    <i className="fab fa-facebook" />
                  </Button> */}
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <img
                alt="..."
                style={{ width: "100%" }}
                src={require("../../Pictures/tedx2023.jpg")}
              />
            </Col>
          </Row>

          <Row className="row-grid" style={{ padding: "30px 0" }}>
            <Col
              xs={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={nit_durgapur}
                class="nit"
              ></img>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <div className="NIT" id="NIT">
                <h1>
                  <span>
                    About <br />
                    NIT Durgapur
                  </span>
                </h1>
                <p>
                  Established in 1960, National Institute of Technology Durgapur
                  is a premier engineering institute within the country, which
                  since its inception has trained proficient engineers and
                  leaders, whose achievements are awe inspiring. Our institute
                  is home to a formidable talent pool, exhibiting remarkable
                  skills in a multitude of domains. Ranked 29th for engineering
                  and 6th among NITs, according to the National Institutional
                  Ranking Framework (NIRF), the excellence displayed by the
                  institute in every academic year is a testament to its
                  promising years ahead.
                </p>

                <br />
                {/* <a
                  className="font-weight-bold text-info mt-5"
                  href="https://lcnitd.co.in/"
                >
                  Show all{" "}
                  <i className="tim-icons icon-minimal-right text-info" />
                </a> */}
              </div>
            </Col>
          </Row>

          <Row
            className="row-grid justify-content-between align-items-center text-left"
            style={{ padding: "30px 0" }}
          >
            <Col xs={12} md={6} lg={6}>
              <h1 className="text-white">
                <span>What is TEDx?</span>
              </h1>
              <p className="text-white mb-3">
                TEDx is an independently organized equivalent to its
                international counterpart, TED. Resonating with TED’s mission of
                researching and discovering “ideas worth spreading”, TEDx aims
                to provide a credible platform to an emerging pool of talent at
                the grassroots, subsequently sparking constructive discussions
                locally.TEDx events are organized by curious individuals who
                seek to discover ideas and spark conversations in their own
                community.
              </p>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <img
                alt="..."
                style={{ width: "100%" }}
                src={require("../../Pictures/aanchal_thakur.jpg")}
                class="TEDx"
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}
